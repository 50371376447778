<template>
  <v-container
    id="customers"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Clients"
    />
      <v-row>
        <v-col
          cols="12"
        >
          <v-card
            class="px-5 py-3"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher un client"
              single-line
              hide-details
            />

            <div class="py-3">
            </div>
            <v-data-table
              class="table-clients"
              :headers="headers"
              :items="clients"
              :search="search"
              :items-per-page="15"
              @click:row="rowClick"
            >
              <template v-slot:[`item.nomCommercial`]="{ item }">
                <b>{{ item.nomCommercial }}</b>
                <v-tooltip
                  bottom
                  v-if="item.contactsAdministratif.length === 0 || item.contactsFacturation.length === 0 "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-1"
                      color="warning"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Contacts manquants</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <span
                  class="badge-chips-small"
                >
                  {{ item.type }}
                </span>
              </template>
              <template v-slot:[`item.adresse`]="{ item }">
                <span v-if="item.adresse">
                  {{ item.adresse.ville }} ({{ item.adresse.departement }})
                </span>
              </template>
            </v-data-table>
          </v-card>
           <div
            class="d-flex justify-end mb-3"
          >
            <v-btn
              color="primary"
              x-large
              :to="{ name: 'CreerClient'}"
            >
              <v-icon class="mr-2">mdi-plus-circle</v-icon>
              Créer un client
            </v-btn>
          </div>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    name: 'Clients',

    data() {
      return {
        search: '',
        headers: [
          {
            sortable: true,
            text: 'Nom',
            value: 'nomCommercial',
          },
          {
            sortable: false,
            text: 'Code facture',
            value: 'codeFacture',
          },
          {
            sortable: true,
            text: 'Type',
            value: 'type',
          },
          {
            sortable: false,
            text: 'Adresse',
            value: 'adresse',
          },
        ],
        clients: [],
      }
    },

    created() {
      this.axios.get('/clients')
        .then((res) => {
          this.clients = res.data;
          this.clients.sort((a, b) => a.nomCommercial.localeCompare(b.nomCommercial));
        })

        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des clients', type: 'warn' });
        });
    },

    methods: {
      rowClick(item, row) {
        this.$router.push({ name: 'ClientDetail', params: { id: item.id } });
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },

  }
</script>

<style scoped>
  .table-clients >>> tbody tr:not(.v-row-group__header) {
    cursor: pointer;
    transition: background-color 0.1s;
  }

  .table-clients >>> tbody tr:not(.v-row-group__header):hover {
    background-color: #f8f8f8;
    font-weight: 400 !important;
  }

  .table-clients >>> .badge-chips-small {
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 0.7rem;
    border: solid 1px black;
  }
</style>
